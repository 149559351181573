import {
  SyllabusCandidate,
  MissingData,
  CentreSyllabus,
} from '../../../common/types'
import { UNRANKED_GRADE } from '../constants'
import { isOCR } from '../../../common/services/util'

export const applySearchFilter = (
  candidates: SyllabusCandidate[],
  filter: string
): SyllabusCandidate[] => {
  const filt = filter.toLowerCase()
  return candidates.filter(
    (c) =>
      c.name.toLowerCase().includes(filt) ||
      c.candidateNumber.replace(/^0+/, '').toLowerCase().startsWith(filt)
  )
}

export const hasErrors = (missingData: MissingData): boolean => {
  return (
    (missingData.missingPathways && missingData.missingPathways > 0) ||
    missingData.missingPredicted > 0
  )
}

export const getErrorCounts = (
  candidates: SyllabusCandidate[],
  syllabus: CentreSyllabus,
  isOcr: boolean
): MissingData => {
  const errors: MissingData = {
    missingPredicted: candidates.filter((candidate) => !candidate.grade).length,
  }

  if (syllabus.pathwayMandatory && isOcr) {
    errors.missingPathways = candidates.filter(
      (candidate) =>
        candidate.grade !== UNRANKED_GRADE &&
        !candidate.pathwayOne &&
        !candidate.pathwayTwo
    ).length
  }

  return errors
}

export const isGraded = (
  candidate: SyllabusCandidate,
  syllabus: CentreSyllabus
) =>
  !candidate.grade ||
  (isOCR() &&
    syllabus.pathwayMandatory &&
    candidate.grade !== UNRANKED_GRADE &&
    !(candidate.pathwayOne || candidate.pathwayTwo))
