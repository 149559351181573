import React from 'react'
import { CentreSyllabus, SyllabusCandidate } from '../../../common'
import { CandidateFull } from './candidate-full'
import { CandidateGrading } from './candidate-grading'
import { ICandidateUpdate } from '../constants'

interface ICandidate {
  idx: number
  candidate: SyllabusCandidate
  grading: string[]
  candidatesInGrade: number
  filter: string
  patchingProgress?: string
  onChange: (update: ICandidateUpdate, cb?: (good: boolean) => void) => void
  isErroneous: boolean
  viewOnly: boolean
  syllabus: CentreSyllabus
  availablePaths: string[]
  pathwaysUpdating: boolean
}

export const Candidate: React.FC<ICandidate> = ({
  isErroneous,
  idx,
  candidate,
  grading,
  candidatesInGrade,
  filter,
  patchingProgress,
  onChange,
  viewOnly,
  availablePaths,
  syllabus,
  pathwaysUpdating,
}) => {
  return filter === 'ALL' ? (
    <>
      <CandidateGrading
        isErroneous={isErroneous}
        candidate={candidate}
        grading={grading}
        candidatesInGrade={candidatesInGrade}
        viewOnly={viewOnly}
        patchingProgress={patchingProgress}
        onChange={onChange}
        availablePaths={availablePaths}
        syllabus={syllabus}
        pathwaysUpdating={pathwaysUpdating}
      />
    </>
  ) : (
    <CandidateFull
      isErroneous={isErroneous}
      idx={idx}
      candidate={candidate}
      grading={grading}
      candidatesInGrade={candidatesInGrade}
      viewOnly={viewOnly}
      patchingProgress={patchingProgress}
      onChange={onChange}
      availablePaths={availablePaths}
      syllabus={syllabus}
      pathwaysUpdating={pathwaysUpdating}
    />
  )
}
