import React, { useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import { CentreSyllabus, SyllabusCandidate } from '../../../common'
import {
  candidateColumnSizes,
  ICandidateUpdate,
  UNRANKED_GRADE,
} from '../constants'
import { PathwaySelector } from '../utilities/pathway-selector/pathway-selector'

interface ICandidateFull {
  idx: number
  candidate: SyllabusCandidate
  grading: string[]
  candidatesInGrade: number
  isErroneous: boolean
  viewOnly: boolean
  patchingProgress?: string
  onChange: (update: ICandidateUpdate, cb?: (good: boolean) => void) => void
  availablePaths: string[]
  syllabus: CentreSyllabus
  pathwaysUpdating: boolean
}

export const CandidateFull: React.FC<ICandidateFull> = ({
  idx,
  candidate: {
    id,
    centreSyllabusId,
    candidateNumber,
    name,
    dob,
    grade,
    allowedGrades,
    lastUpdated,
    pathwayOne,
    pathwayTwo,
  },
  grading,
  isErroneous,
  viewOnly,
  candidatesInGrade,
  patchingProgress,
  onChange,
  availablePaths,
  syllabus,
  pathwaysUpdating,
}): JSX.Element => {
  const canSelectPathways = useMemo(() => !!syllabus.paths?.length, [
    syllabus.paths,
  ])
  const amountColumnsWithPathway = 5
  const amountColumnsWithoutPathway = 4

  const columnCount = useMemo(
    () =>
      canSelectPathways
        ? amountColumnsWithPathway
        : amountColumnsWithoutPathway,
    [canSelectPathways]
  )
  const sizes = useMemo(() => candidateColumnSizes[columnCount], [columnCount])

  return (
    <Row
      className={
        isErroneous
          ? 'reddish-background candidate-row px-25 py-3'
          : 'candidate-row px-25 py-3'
      }
      data-testid="candidate-row"
    >
      <Col className="text-left name" md={sizes[0]}>
        {name}
      </Col>
      <Col className="text-center font-normal font-weight-bold" md={sizes[1]}>
        {dob}
      </Col>
      <Col className="text-center font-normal font-weight-bold" md={sizes[2]}>
        {candidateNumber}
      </Col>
      <Col className="text-center" md={sizes[3]}>
        <span className="candidate-grade px-3 py-25">{grade}</span>
      </Col>
      {canSelectPathways &&
        (grade !== UNRANKED_GRADE ? (
          <Col className="text-right pr-2" md={sizes[4]}>
            <PathwaySelector
              availablePaths={availablePaths}
              pathwayOne={pathwayOne}
              pathwayTwo={pathwayTwo}
              viewOnly={viewOnly}
              onChange={(e) =>
                onChange({
                  grade,
                  pathwayOne: e.pathwayOne,
                  pathwayTwo: e.pathwayTwo,
                })
              }
              isPendingPatch={!!patchingProgress && pathwaysUpdating}
            />
          </Col>
        ) : (
          <Col className="text-center">-</Col>
        ))}
    </Row>
  )
}
