import React from 'react'
import {
  ValuationStatus,
  IStatusAndControlBlockProps,
  isAdmin,
  isCAAdmin,
  isHOC,
  isTeacher,
} from '../../../common'
import { DownloadTemplateButton } from './download-template-button'
import { SyllabusReopenButton } from '../../syllabus-reopen-button/syllabus-reopen-button'
import { DeadlineButton } from '../deadline-button/deadline-button'
import { SyllabusProgressStatus } from './syllabus-progress-status'
import { SyllabusSubmissionStatus } from './syllabus-submission-status'

export const StatusAndControlBlock: React.FC<IStatusAndControlBlockProps> = ({
  businessUnit,
  title,
  subTitle,
  syllabusId,
  centreId,
  totalCandidates,
  gradedCandidates,
  inProgress,
  user,
  syllabusTemplateFileName,
  closed,
  is,
  onUpdate,
}) => {
  const styleMap: Record<string, string> = {
    ci: 'pr-3',
    ocr: 'pl-3',
  }

  return (
    <>
      <SyllabusProgressStatus
        syllabusId={syllabusId}
        inProgress={inProgress}
        closed={closed}
        businessUnitCssClass={styleMap[businessUnit]}
        is={is}
      />
      {user && (isAdmin(user) || isTeacher(user) || isHOC(user)) && (
        <DownloadTemplateButton
          centreId={centreId}
          id={syllabusId}
          fileName={syllabusTemplateFileName}
          businessUnitCssClass={styleMap[businessUnit]}
        />
      )}
      {!closed && user && isCAAdmin(user) && is(ValuationStatus.APPROVED) && (
        <SyllabusReopenButton
          btnText="Status"
          centreId={centreId}
          header={title}
          subheader={subTitle}
          ancillery={`${totalCandidates} candidates`}
          syllabusId={syllabusId}
          gradedCandidates={gradedCandidates}
          totalCandidates={totalCandidates}
          syllabusReopened={() => onUpdate && onUpdate()}
          businessUnitCssClass={styleMap[businessUnit]}
        />
      )}
      {user && isCAAdmin(user) && (
        <DeadlineButton
          disabled={!closed}
          header={title}
          subheader={subTitle}
          ancillery={`${totalCandidates} candidates`}
          centreId={centreId}
          syllabusId={syllabusId}
          businessUnitCssClass={styleMap[businessUnit]}
          onUpdate={() => onUpdate && onUpdate()}
        />
      )}
      <SyllabusSubmissionStatus
        inProgress={inProgress}
        closed={closed}
        user={user}
        businessUnitCssClass={styleMap[businessUnit]}
        is={is}
        data-testid={`syllabus-progress-submission-status-${syllabusId}`}
      />
    </>
  )
}
