import React, { useMemo } from 'react'
import { Col } from 'reactstrap'
import { MissingData } from '../../../common'
import getTextFromToken from '../../../common/services/tokenised-text'

export const CandidateErrorInfo: React.FC<MissingData> = ({
  missingPredicted,
  missingPathways,
}) => {
  const showMissingPredictedError = missingPredicted > 0
  const showMissingPathwayError = missingPathways && missingPathways > 0

  if (!showMissingPredictedError && !showMissingPathwayError) {
    return null
  }

  return (
    <Col lg={12}>
      <div className="border rounded border-danger px-4 py-3">
        {showMissingPredictedError && (
          <li className=" text-danger font-weight-bold">
            {`${missingPredicted} candidates with missing ${getTextFromToken(
              'assessedGrades'
            ).toLowerCase()}`}
          </li>
        )}
        {showMissingPathwayError ? (
          <li className=" text-danger font-weight-bold">
            {`${missingPathways} candidates are missing a pathway`}
          </li>
        ) : null}
      </div>
    </Col>
  )
}
