import { useCallback, useMemo } from 'react'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import { PreSignedUploadUrl } from '../types/types'
import { useDownloadFile } from './use-download-file'

interface UsePresignedDownloadProps {
  url: string
  params?: object
  fileName: string
}

interface UsePresignedDownloadReturnProps {
  pending: boolean
  download: () => void
}

export const usePresignedDownload = ({
  url,
  params,
  fileName,
}: UsePresignedDownloadProps): UsePresignedDownloadReturnProps => {
  const urlMemo = useMemo(
    () => ({
      url,
      params,
    }),
    [url, params]
  )

  const getPresignedUrlTask = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, urlMemo)

  const downloadTask = useDownloadFile({
    fileUrl: `${getPresignedUrlTask.result?.data.url}`,
    fileName,
  })

  useAsyncRun(getPresignedUrlTask.result && downloadTask)

  const pending = useMemo(
    () =>
      (getPresignedUrlTask.pending && getPresignedUrlTask.started) ||
      (getPresignedUrlTask.result != null &&
        downloadTask.pending &&
        downloadTask.started),
    [
      getPresignedUrlTask.pending,
      getPresignedUrlTask.started,
      getPresignedUrlTask.result,
      downloadTask.pending,
      downloadTask.started,
    ]
  )

  const download = useCallback(() => {
    getPresignedUrlTask.start()
  }, [getPresignedUrlTask])

  return useMemo(
    () => ({
      pending,
      download,
    }),
    [pending, download]
  )
}
