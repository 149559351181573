import React, { FC } from 'react'
import './download-pathways.scss'
import {
  usePresignedDownload,
  DownloadButton,
  DataSource,
} from '../../../../common'

export const DownloadPathwaysButton: FC<{
  centreId: string
  dataSource: string
}> = ({ centreId, dataSource }) => {
  const { pending, download } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/pathwaysguide`,
    fileName: 'Pathways.csv',
  })

  if (dataSource !== DataSource.CAMS) {
    return null
  }

  return (
    <DownloadButton
      text="Download Pathways"
      pending={pending}
      download={download}
      color="e4"
      className="mr-3 text-center text-secondary download-pathways"
    />
  )
}
