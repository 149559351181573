import {
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  IdToken,
  PopupLoginOptions,
  RedirectLoginResult,
  getIdTokenClaimsOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
} from '@auth0/auth0-spa-js'
import { DataSource, FileType } from '../index'

export enum ValuationStatus {
  NOT_STARTED = 'not_started',
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete',
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  OPENED = 'opened',
}

export interface SyllabusCandidate {
  id: string
  centreSyllabusId: string
  candidateNumber: string
  name: string
  dob: string
  grade?: string
  allowedGrades: string[]
  lastUpdated?: string
  pathwayOne: string
  pathwayTwo: string
}

export interface CentreSyllabusDto {
  closed: boolean
  id: string
  centreId: string
  syllabusName: string
  syllabusCode: string
  qualification: string
  qualLevel: string
  lastUpdated?: number
  totalCandidates: number
  gradedCandidates: number
  valuationStatus: ValuationStatus
  percentageCompleted?: number
  paths?: string[]
  pathSuite: number
  tiesAllowed?: boolean
  dataSource: DataSource
  pathwayMandatory: boolean
  deadline: number
}

export interface CentreSyllabus extends CentreSyllabusDto {
  percentageCompleted?: number
  syllabusReopened?: (reopened: boolean) => void
}

export interface PathParam {
  id: string
  syllabusId?: string
}

export interface SpecificationFile {
  bucket: string
  absolutePath: string
  fileName: string
  fileType: FileType
  size: string
  creationDate: string
}

export interface ManagedUser {
  userId?: string
  name: string
  surname: string
  email: string
  role?: string
  confirmed?: boolean
  centres?: string[]
}

export interface User {
  email: string
  centres: string[]
  user_id: string
  sub: string
  picture: string
  name: string
  nickname: string
  roles: string[]
  email_verified: boolean
  isPlaceholder?: boolean
}
export interface AuthCallback {
  onRedirectCallback?: (appState: any) => void
}
export interface AuthContextProps {
  isAuthenticated: boolean
  user?: User
  currentCentre?: string
  loading?: boolean
  loginWithPopup(o?: PopupLoginOptions): Promise<void>
  handleRedirectCallback(): Promise<RedirectLoginResult>
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>
  loginWithRedirect(o?: RedirectLoginOptions): Promise<void>
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>
  logout(o?: LogoutOptions): void
  setCurrentCentre(cenreId: string): void
}

export interface AppState {
  targetUrl: string
}
export interface AuthConfig {
  domain: string
  client_id: string
  audience?: string
  scope?: string
  role_url: string
  centre_url: string
}

export interface InstructionMaterialDTO {
  materials: string[]
}

export interface LocalConfig {
  auth0Config?: AuthConfig
  siteEnabled?: boolean
}

export interface SystemAvailabilityConfigDTO {
  captureAvailableFrom: number
  captureAvailableTo: number
  aamAvailableFrom: number
  aamAvailableTo: number
}

export interface SystemAvailabilityConfig {
  captureAvailableFrom?: Date
  captureAvailableTo?: Date
  aamAvailableFrom?: Date
  aamAvailableTo?: Date
}

export interface Config extends LocalConfig, SystemAvailabilityConfig {}

export interface ConfigState {
  mainConfigLoaded: boolean
  systemAvailabilityConfigLoaded: boolean
  config: Config
}
export interface PreSignedUploadUrl {
  url: string
  uuid: string
}
export interface UploadStatus {
  validationErrors: ValidationError[]
  status: string
  id: string
  updatedItems: number
  aggregatedErrors: { [key: string]: number }
}
export interface ValidationError {
  errorCode: string
  errorMessage: string
  fieldName: string
  fieldValue: string
  uniqueId: string
}
export interface MissingData {
  missingPredicted: number
  missingPathways?: number
}

export interface ISyllabusProgressProps {
  title: string
  subTitle: string
  syllabusId: string
  centreId: string
  totalCandidates: number
  gradedCandidates: number
  inProgress: boolean
  user: User | undefined
  syllabusTemplateFileName: string
  closed: boolean
  is: (status: string) => boolean
  onUpdate?: () => void
}

export interface IStatusAndControlBlockProps extends ISyllabusProgressProps {
  businessUnit: string
}
