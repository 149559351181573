import React from 'react'
import {
  isCAAdmin,
  isOCR,
  isCambridgeInternational,
  DownloadButton,
  useAuth0,
  usePresignedDownload,
} from '../../../common'

import ReactGA from 'react-ga'

export const OfqualDownloadReport = () => {
  const { user } = useAuth0()

  const {
    pending: ofQualTagsReportPending,
    download: ofQualTagsReportDownload,
  } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/ofqual-tags-report/url`,
    fileName: `ofqual_tags.csv`,
  })

  const {
    pending: ofQualCentreReportPending,
    download: ofQualCentreReportDownload,
  } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/ofqual-centre-report/url`,
    fileName: `ofqual_centre.csv`,
  })

  const {
    pending: ofQualDownloadPending,
    download: downloadOfQualReport,
  } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/ofqual-report/url`,
    fileName: 'ofqual.csv',
  })

  if (!(user && isCAAdmin(user))) return <></>

  return (
    <>
      {isOCR() && (
        <>
          <DownloadButton
            text="Download Ofqual Centre report"
            download={ofQualCentreReportDownload}
            pending={ofQualCentreReportPending}
            dataTestid="ca-admin-controls-download-ofqual-centre-report-button"
          />
          <DownloadButton
            text="Download Ofqual TAG report"
            download={ofQualTagsReportDownload}
            pending={ofQualTagsReportPending}
            dataTestid="ca-admin-controls-download-ofqual-tag-report-button"
          />
        </>
      )}
      {isCambridgeInternational() && (
        <DownloadButton
          text="Download OfQual report"
          pending={ofQualDownloadPending}
          download={() => {
            ReactGA.event({
              category: 'CA Support',
              action: 'Download OfQual Report',
            })
            downloadOfQualReport()
          }}
          dataTestid="ca-admin-controls-download-ofqual-report-button"
        />
      )}
    </>
  )
}
