import React, { useMemo, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { formatDate } from '../candidates-list/constants'
import {
  ProjectModal,
  RsModalHeader,
  RsModalBody,
  Checkbox,
} from '../../common'
import ReactMarkdown from 'react-markdown'

import ciDeclarationContent from '../upload/ci-declaration-content.md'
import ocrDeclarationContent from '../upload/ocr-declaration-content.md'
import { isCambridgeInternational, isOCR, CentreSyllabus } from '../../common'
import './hoc-approve-modal.scss'

const CHECKLIST: { heading: string; statements: string[] }[] = [
  {
    heading: 'School-assessed grades',
    statements: [
      // 'I confirm that these predicted grades, and the rank orders of candidates, represent the professional judgements made by my staff.',
      'I confirm that these school-assessed grades represent the professional judgements made by my staff.',
      'I confirm that these school-assessed grades are based on evidence of each candidate’s performance.',
      'I confirm all staff involved in determining school-assessed grades have taken into account Cambridge’s guidance about avoiding bias.',
      // 'I confirm staff involved in predicting grades and rank ordering candidates have taken into account guidance about avoiding bias, which could include unconscious biases related to the gender, ethnicity, religion, classroom behaviour or other characteristics of the candidate.',
      // 'I confirm that staff involved in predicting grades and rank ordering candidates have taken into account guidance about special educational needs or adverse circumstances.',
      'I confirm the judgements of the staff involved in determining school-assessed grades have  not been affected by a candidate’s behaviour, character, appearance or social background, or the performance of their siblings, parental or other stakeholder opinions or pressure, or the knowledge of grades needed to meet a university offer.',
      'I confirm that staff involved in determining school-assessed grades have taken into account guidance about access arrangements and special consideration.',
      'I confirm that the staff involved in determining school-assessed grades have followed the guidance on authenticity when using candidate evidence to determine school-assessed grades.',
      'I confirm that I have submitted any and all declarations of interest in accordance with section 2.1.5 of the Cambridge Handbook.',
    ],
  },
  {
    heading: 'Providing evidence',
    statements: [
      'I understand that Cambridge may ask to be provided with the evidence and Rationale Document for some or all of my candidates.',
      'I confirm that the evidence and Rationale Document will remain available to me from now until the release of certificates.',
      'I will provide this evidence and Rationale Document to Cambridge within the timescales specified in any request for evidence.',
      'I understand that failure to provide the evidence and Rationale Document upon request, and within the timescales specified in the request, may mean that results will not be issued for my candidates.',
      'I understand that school-assessed grades will be considered as final by Cambridge at the time of submission.',
    ],
  },
  {
    heading: 'Malpractice',
    statements: [
      'I confirm that the ‘final’ school-assessed grades submitted to Cambridge through this portal have not been discussed with candidates, their parents/legal guardians, or any other individuals outside the centre.',
      'I understand that any action that breaks Cambridge regulations and potentially threatens the integrity of the grades issued may be treated as suspected malpractice in accordance with section 5.6 of the Cambridge Handbook. Suspected malpractice may result in the delay of results, disqualification of candidates and / or sanctions against my centre and centre staff, including withdrawal of my centre status or right to offer Cambridge qualifications.',
    ],
  },
  {
    heading:
      'Understanding the Quality Assurance process Cambridge will follow',
    statements: [
      'I understand that Cambridge will carry out external quality assurance checks on school-assessed grades.',
      'I understand that I will be required to send Cambridge work in the portfolios for these candidates, together with the Rationale Document, at Cambridge International’s request.',
      'I understand that if Cambridge finds any issues during the quality assurance process, we will contact the centre, and Cambridge may ask for changes to the centre’s process and to reconsider their judgements.',
      'I understand that Cambridge will withhold students’ grades until issues identified during the quality assurance process have been resolved to the satisfaction of both the centre and Cambridge.',
    ],
  },
]

interface IHOCApproveModal {
  syllabus: CentreSyllabus
  isOpen: boolean
  close: () => void
  approve: () => void
}

export const HOCApproveModal: React.FC<IHOCApproveModal> = ({
  syllabus,
  isOpen,
  close,
  approve,
}) => {
  const [checklist, updateChecklist] = useState(
    // CHECKLIST.map((element, index) =>
    //   element.statements.map((desc, idx) => ({ idx: String(index) + String(idx), desc, checked: false }))
    // ).flat()
    CHECKLIST.map((element, index) => ({ idx: index, checked: false }))
  )

  const [confirmation, updateConfirmation] = useState(false)

  const allChecked = useMemo(() => checklist.every((it) => it.checked), [
    checklist,
  ])

  const check = useMemo(
    () => (idx: number, check: boolean) => {
      updateChecklist((old) =>
        old.map((it) =>
          it.idx !== idx
            ? it
            : {
                ...it,
                checked: check,
              }
        )
      )
    },
    [updateChecklist]
  )

  return (
    <ProjectModal
      className="hoc-approval-modal"
      centered
      isOpen={isOpen}
      dataTestid="hoc-approval-modal"
    >
      <RsModalHeader toggle={close} className="bg-white px-5 pt-5 pb-25">
        <h6
          className="mb-0 font-weight-bold text-success"
          style={{ fontSize: '22px' }}
        >{`${syllabus.syllabusName}`}</h6>
        <h5 className="text-secondary font-weight-semi-bold">
          <span>{`${syllabus.qualification} (${syllabus.syllabusCode})`}</span>
          <span className="ml-3 mr-3">|</span>
          <span>{`${syllabus.totalCandidates} candidates`}</span>
        </h5>
      </RsModalHeader>
      <RsModalBody className="pt-4 px-5 pb-5 rounded-bottom">
        <Row className="mb-4">
          <Col>
            <div className="markdown-content">
              {isCambridgeInternational() && (
                <ReactMarkdown source={ciDeclarationContent} />
              )}
              {isOCR() && <ReactMarkdown source={ocrDeclarationContent} />}
            </div>
          </Col>
        </Row>
        {isCambridgeInternational() && (
          <Row>
            <Col className="ci-declarations">
              {CHECKLIST.map(({ heading, statements }, index) => (
                <div key={heading}>
                  <h6>{heading}</h6>
                  <ul>
                    {statements.map((statement) => (
                      <li key={statement}>{statement}</li>
                    ))}
                  </ul>
                  <Checkbox
                    value={checklist[index].checked}
                    toggle={(newValue) =>
                      void check(
                        index,
                        newValue !== undefined
                          ? newValue
                          : !checklist[index].checked
                      )
                    }
                    variant="yes-no"
                  />
                </div>
              ))}
            </Col>
          </Row>
        )}
        <Row className="mb-4">
          <Col className="d-inline-flex declaration-confirmation">
            <Checkbox
              value={confirmation}
              toggle={() => updateConfirmation(!confirmation)}
            />
            <h6 className="font-weight-bold m-0">
              {`${
                isOCR()
                  ? 'I confirm that the data we submit is final and correct. I agree to the Head of Centre declaration on behalf of my centre'
                  : 'I confirm that the data we submit is final and correct. I agree to the Declaration of Authority on behalf on my centre'
              }.`}
            </h6>
          </Col>
        </Row>
        <Row className="mt-4 ml-0 justify-content-between">
          <Col>
            <h6 className="font-weight-bold">
              {isOCR() ? 'Date authorised' : 'Date and time authorised'}
            </h6>
            <h6 className="font-weight-bold text-secondary">
              {formatDate(new Date(), true, true)}
            </h6>
          </Col>
          <Col className="d-inline-flex justify-content-end align-items-center">
            {isCambridgeInternational() && (
              <Button
                onClick={approve}
                color="success"
                disabled={!(allChecked && confirmation)}
              >
                Submit
              </Button>
            )}
            {isOCR() && (
              <Button
                onClick={approve}
                className="bg-success"
                disabled={!confirmation}
              >
                Submit
              </Button>
            )}
          </Col>
        </Row>
      </RsModalBody>
    </ProjectModal>
  )
}
