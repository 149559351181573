import React from 'react'
import Switch from 'react-switch'
import { Col, Row } from 'reactstrap'

import { CandidateErrorInfo } from '../../candidate/candidate-error-info'
import { MissingData } from '../../../../common/types'

interface ICandidateErrorsProps {
  showErrors: boolean
  showOnlyErrors: boolean
  filteredErrors: MissingData
  setShowOnlyErrors: React.Dispatch<React.SetStateAction<boolean>>
}

export const CandidateErrors: React.FC<ICandidateErrorsProps> = ({
  showErrors,
  showOnlyErrors,
  filteredErrors,
  setShowOnlyErrors,
}) => {
  return (
    <Row className="align-items-end mb-45">
      {showErrors && (
        <CandidateErrorInfo
          missingPredicted={filteredErrors.missingPredicted}
          missingPathways={filteredErrors.missingPathways}
        />
      )}
      <Col className="text-right">
        <label>
          <span className="mr-2 font-weight-bold align-middle">
            Show only errors
          </span>
          <Switch
            className="error-switch align-middle"
            height={20}
            width={32}
            handleDiameter={16}
            checked={showOnlyErrors}
            onChange={() => setShowOnlyErrors(!showOnlyErrors)}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#c31918"
          />
        </label>
      </Col>
    </Row>
  )
}
